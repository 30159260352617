import { render, staticRenderFns } from "./CognitiveMXZZ.vue?vue&type=template&id=f0893950&scoped=true"
import script from "./CognitiveMXZZ.vue?vue&type=script&lang=js"
export * from "./CognitiveMXZZ.vue?vue&type=script&lang=js"
import style0 from "./CognitiveMXZZ.vue?vue&type=style&index=0&id=f0893950&prod&scoped=true&lang=css"
import style1 from "./CognitiveMXZZ.vue?vue&type=style&index=1&id=f0893950&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0893950",
  null
  
)

export default component.exports