<template>
    <div class="Modelmaking">
        <div class="Model_div">
            <ul class="m_ul clearfix">
                <li class="fl">
                    <p class="li_title"> <span><i class="iconfonts icons-xinghaoguige"></i> </span> 参考模板</p>
                    <div class="li_content clearfix">
                        <div class="fl li_div_img">
                            <el-carousel>
                                <el-carousel-item v-for="(item,index) in forms.template.value.img" :key="item">
                                    <el-image fit="cover" :src="item" :preview-src-list="forms.template.value.img" :initial-index="index"  alt="" />
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="fl div_cont">
                            <p class="div_name">{{forms.template.value.name}}</p>
                            <p class="div_jianj">
                                <span>{{forms.template.value.desc}}</span>
                            </p>
                            <p class="p_tit" v-for="(item,index) in forms.template.value.tag" :key="index">
                                <strong>{{item.name}}</strong>：
                                <span>{{item.val}}</span>
                            </p>
                        </div>
                    </div>
                </li>
                <li class="fl" v-for="(items,indexs) in forms.p1.values" :key="indexs">
                    <p class="li_title"> <span><i class="iconfonts icons-xinghaoguige"></i> </span>产品规格{{indexs+1}}</p>
                    <div class="li_content clearfix">
                        <div class="fl li_div_img">
                            <el-carousel>
                                <el-carousel-item v-for="imgm in items.img" :key="imgm">
                                    <el-image  fit="cover" :src="imgm" alt="" :preview-src-list="items.img" :initial-index="index" />
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="fl div_cont">
                            <p class="div_name">{{items.name}}</p>
                            <p class="div_jianj">
                                <span>{{items.desc}}</span>
                            </p>
                            <p class="p_tit" v-for="(itemss,indexss) in items.tag" :key="indexss">
                                <strong>{{itemss.name}}</strong>：
                                <span>{{itemss.val}}</span>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            forms:{
                template:{
                    value:{
                        tag:[]
                    }
                },
                p1:{
                    values:[]
                }
            }
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects6({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                        this.forms = res.data.data.data
                        var str = this.form
                        this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher//Copypaper/CognitiveMXZZ.css';
</style>
<style>
.li_div_img .el-carousel{
    width: 100%;
    height: 100%;
}
.li_div_img .el-carousel__container{
    height: 100%;
}
.li_div_img .el-carousel__arrow{
    display: none!important;
}
.li_div_img .el-carousel__arrow:hover{
    display: none!important;
}
.li_div_img .el-carousel__indicators{
    display: none;
}
</style>